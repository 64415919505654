import React, {useState} from 'react';
import Robozinho from './Components/Robozinho/Robozinho'
import './App.css';

function App() {
  const robo = useRobo(true)
 
  return (
    <div className="robottiWdg">
      <Robozinho roboFechado={robo.roboFechado}
                   onClick={robo.ToggleRobo}
                   ></Robozinho> 
    </div>
  );
}

export default App;

function useRobo() {
  const [roboFechado, setRoboFechado] = useState(true);

  function ToggleRobo() {
      
      setRoboFechado(!roboFechado);
  }

  return ({
      roboFechado,
      ToggleRobo
  })
}
