import React from 'react';
import './Robozinho.css';
import fecha from './fecha.png'
import {hello, urlRobo, logoRobo} from '../../Config'


const robozinho = (props) => {
    
    let hi = hello('en')
    let moi = hello('fi')

    return (
        <div>
            <div className="falaRobo">
                <p className="palavrasRobo">{hi.hello}</p> 
                <p className="palavrasRobo">{hi.message}</p>
            </div> 
            <div className="falaRobo2">
                <p className="palavrasRobo">{moi.hello}</p> 
                <p className="palavrasRobo">{moi.message}</p>
            </div>   
            <div className="robozinho">  
                
                {props.roboFechado ?
                    
                        
                    <div className="roboIframe">
                        <img alt="logoRobo" 
                            src={logoRobo}
                            style={{height:60, width:60}}
                            onClick={() => props.onClick()}></img>
                    </div>
                    
                    
                :
                    <div>
                        <iframe className="roboIframeAberto"
                                src={urlRobo}
                                title="Chatbot"
                        />
                        <div className="fecha">
                            <button className="btnFecha">
                                <img alt="closeRobot" src={fecha} onClick={() => props.onClick()}></img> 
                            </button>
                        </div>
                    </div>
                }        
            
            </div>
        </div>
    )
}

export default robozinho;

