
const logoRobo = 'https://valkokettusite.s3.amazonaws.com/Didaskalos/static/media/didaskalosbot-02.png'

const hellos = [
    { language: 'fi',
      hello: 'Hei!',
      message: 'Onko sinulla kysymyksiä koulusta tai haluatko tarkistaa suomen kielen tasosi? Voisinko auttaa?' 
    },
    { language: 'en',
      hello: 'Hi!',
      message: 'Do you have any questions about our school or do you want to check your finnish level? How can I help you?' 
    }
]

const linguaPadrao = 'en'

const urlRobo = 'https://master.d1c3fs8hs7ev1e.amplifyapp.com/'

const hello = (lingua) => {
    
  return (
  
    hellos.find(({language}) => language === lingua)

  )
}

export { hello,
         linguaPadrao,
         urlRobo,
         logoRobo }